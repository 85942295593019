<template lang="pug">
    el-dialog#angajati-dialog( title="Citeste card" :visible.sync="valueMutable" class="my-dialog-class" append-to-body @opened="opened" @closed="closed")
        .cititor-card(ref="cititorCard" tabindex="0" @keypress="onKeyPress" @focusout="onFocusOut") 
            div Citeste card
            div {{ card }}
        span( slot="footer" class="dialog-footer" )
            el-button( @click="valueMutable = false" ) Renunta
</template>
<script>
export default {
    data() {
        return {
            valueMutable: this.value,
            card: ""
        }
    },
    props: {
        value: {
            required: true,
            type: Boolean
        }
    },
    watch: {
        valueMutable(newVal, odlVal) {
            if (newVal != odlVal) {
                this.$emit('input', newVal)
            }
        },
        value(newVal, odlVal) {
            if (newVal != odlVal) {
                this.valueMutable = newVal
            }
        },
    },
    methods: {
        opened() {
            this.card = ""
            this.$refs.cititorCard.focus()
        },
        closed() {
        },
        onFocusOut(evt) {
            console.log(evt)
            setTimeout(() => {
                if (this.$refs.cititorCard) {
                    this.$refs.cititorCard.focus()
                }
            }, 300)
        },
        onKeyPress(event) {
            if (event.key == "Enter") {
                this.finalizeazaCitire()
            } else {
                this.card += String.fromCharCode(event.charCode)
            }
        },
        finalizeazaCitire() {
            this.valueMutable = false
            this.$emit("citire", this.card)
        }
    },
    mounted() {
        this.valueMutable = this.value
    }
}
</script>
<style lang="less" scoped>
#angajati-dialog {
    .cititor-card {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
</style>